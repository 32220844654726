import Vue from 'vue'
import Vuex from 'vuex'
/*import auth from '@/store/modules/auth'
import modalAssign from '@/store/modules/modal-assign'
import modalReport from '@/store/modules/modal-report'*/
import VuexPersistance from 'vuex-persist/dist/umd'
/*import { Ability } from '@casl/ability'
import abilityPlugin from '@/store/modules/auth/ability'*/

Vue.use(Vuex)

const vuexLocal = new VuexPersistance({
    storage: window.localStorage,
    //Persistimos únicamente las variables que necesitamos
    reducer: (state) => ({
        userCity: state.userCity,
        userCityDate: state.userCityDate,
        /*auth: {
            token: state.auth.token
        }*/
    })
})

export default new Vuex.Store({
    plugins: [vuexLocal.plugin, /*abilityPlugin*/],
    /*modules: {
        auth,
        modalAssign,
        modalReport
    },*/
    state: {
        loading: false,
        sidebarCollapsed: false,
        userCity: '',
        userCityDate: null,
        bodyClass: null
    },
    /*getters: {
        ability() {
            return new Ability([], {
                subjectName(subject) {
                    return !subject || typeof subject === 'string'
                        ? subject
                        : subject[TYPE_KEY]
                }
            })
        }
    },*/
    actions: {
        setLoading({ commit }, isLoading) {
            commit('setLoading', isLoading, { root: true })
        }
    },
    mutations: {
        setLoading(state, bool) {
            state.loading = bool
        },
        /*setSidebarCollapsed(state, bool) {
            state.sidebarCollapsed = bool
        },*/
        setUserCity(state, userCity) {
            state.userCity = userCity
            state.userCityDate = new Date().toUTCString()
        },
        setBodyClass(state, bodyClass) {
            state.bodyClass = bodyClass
        }
    }
})
