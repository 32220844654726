export default [
    {
        path: '/',
        component: () => import( /* webpackChunkName: "main" */ '@/views/public/Main.vue')
    },
    {
        path: '/hipoteca/:slug',
        name: 'valuation',
        component: () => import( /* webpackChunkName: "main-property" */ '@/views/public/Valuation.vue')
    },
    { 
        path: '/gracias', 
        component: () => import( /* webpackChunkName: "thanks" */ '@/views/public/Thanks.vue')
    },
    { 
        path: '/404', 
        component: () => import( /* webpackChunkName: "404" */ '@/views/404.vue')
    },
    { 
        path: '/politica-de-cookies',
        component: () => import( /* webpackChunkName: "Cookies" */ '@/views/public/Cookies.vue')
    },
    {
        path: '*',
        name:'404', 
        component: () => import( /* webpackChunkName: "404" */ '@/views/404.vue')
    }
]